// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`https://img.icons8.com/plumpy/24/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('blog'),
  cart: getIcon('cart'),
  chat: getIcon('chat'),
  mail: getIcon('mail'),
  user: getIcon('user'),
  kanban: getIcon('kanban'),
  banking: getIcon('bank-card-dollar'),
  booking: getIcon('bank-card-dollar'),
  invoice: getIcon('invoice'),
  calendar: getIcon('calendar'),
  ecommerce: getIcon('total-sales'),
  analytics: getIcon('combo-chart'),
  dashboard: getIcon('dashboard'),
  product: getIcon('product'),
  shop: getIcon('shop'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'sales', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      // {
      //   title: 'users',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.new },
      //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      {
        title: 'users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.v1.shopList },
        // ],
      },
      {
        title: 'shops',
        path: PATH_DASHBOARD.shop.root,
        icon: ICONS.shop,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.v1.shopList },
        // ],
      },
      {
        title: 'products',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.product,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.v1.shopList },
        // ],
      },
      {
        title: 'orders',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.invoice,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.v1.shopList },
        // ],
      },
      // Sales
      // {
      //   title: 'sales',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  {
    subheader: 'Okeyo v1',
    items: [
      // USER
      {
        title: 'shops',
        path: PATH_DASHBOARD.v1.shopList,
        icon: ICONS.user,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.v1.shopList },
        // ],
      },
    ],
  },
  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     // {
  //     //   title: 'mail',
  //     //   path: PATH_DASHBOARD.mail.root,
  //     //   icon: ICONS.mail,
  //     //   info: (
  //     //     <Label variant="outlined" color="error">
  //     //       +32
  //     //     </Label>
  //     //   ),
  //     // },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'board', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
